<template>
  <div class="container-height flex text-center items-center justify-center px-4">
    <div v-if="this.userProfile.trialStartsAt === null">
      <div v-if="isItTooEarlyToResetTrial()">
        <p>It's too early to reset your trial.</p>
      </div>
      <div class="flex flex-col items-center justify-between gap-y-4" v-else>
        <p>You can extend your trial by clicking the following button.</p>
        <new-button type="primary" @click="resetTrial">
          I want to extend my trial
        </new-button>
      </div>
    </div>
    <div v-else>
      <p>
        Your trial period has already been reset!<br />
        Please <a @click="openCrisp" href="javascript:;">contact us</a> if you need another
        extension.
      </p>
    </div>
  </div>
</template>

<script>
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { mapGetters } from 'vuex';
  import controller from '@/controller';
  import { trialPeriodCalculator } from '@/../functions/src/util/trialPeriodCalculator';

  export default {
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    methods: {
      isItTooEarlyToResetTrial() {
        return trialPeriodCalculator(this.userProfile).daysLeft > 5;
      },
      openCrisp() {
        $crisp.push(['do', 'chat:open']);
      },
      resetTrial() {
        controller.resetTrial(this.currentUser)
          .then(() => {
            this.swalModal({
              title: 'Good news',
              text: 'Your trial period has been reset!',
              type: 'warning',
            });
          })
          .catch(error => {
            if (error.response.status === 403) {
              const message = 'Your trial period has already been reset! Please contact us if you need another extension';
              this.swalModal({
                title: 'Sorry',
                text: message,
                showCancelButton: false,
                type: 'warning',
              });
            }
          });
      },
    },
    mixins: [SwalModalMixin]
  }
</script>

<style lang="scss" scoped>
  .container-height {
    height: calc(100vh - 96px);
  }
</style>
